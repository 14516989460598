function jsonToQueryString(json) {
  const jsonAfterRemovingUndefinedAndNull = Object.fromEntries(
    Object.entries(json).filter(([key, value]) => value !== null && value !== undefined)
  );
  return (new URLSearchParams(jsonAfterRemovingUndefinedAndNull)).toString();
}

export const editPersonUrl = (userId) => `/users/${userId}/edit`;

export const personUrl = (wcaId) => `/persons/${wcaId}`;

export const personsUrl = `/persons`;
export const usersUrl = `/users`;

export const newResultUrl = (competitionId, roundId) => `/competitions/${competitionId}/admin/results/${roundId}/new`;
export const resultUrl = (id) => `/admin/results/${id}`;
export const editResultUrl = (id) => `/admin/results/${id}/edit`;

export const newScrambleUrl = (competitionId, roundId) => `/competitions/${competitionId}/admin/scrambles/${roundId}/new`;
export const scrambleUrl = (id) => `/admin/scrambles/${id}`;
export const editScrambleUrl = (id) => `/admin/scrambles/${id}/edit`;

export const postsUrl = (page, format = 'json') => {
  // In this case id holds the page number ;)
  return `/posts.${format}?page=${page}`;
};

export const incidentsUrl = (perPage, page, tags = undefined, searchString = undefined, competitions = undefined, format = 'json') => {
  const searchParams = new URLSearchParams(`per_page=${perPage}&page=${page}`);
  if (tags && tags.length > 0) {
    searchParams.append(`tags`, `${tags}`);
  }
  if (competitions) {
    searchParams.append(`competitions`, `${competitions}`);
  }
  if (searchString) {
    searchParams.append(`q`, searchString);
  }
   return `/incidents.${format}?${searchParams.toString()}`;
}

export const newIncidentUrl = `/incidents/new`;
export const incidentUrl = (id) => `/incidents/${id}`;

export const homepageUrl = `/`

export const createCompetitionUrl = `/competitions`;
export const competitionUrl = (id) => `/competitions/${id}`;
export const adminCompetitionUrl = (id) => `/competitions/${id}/edit/admin`;

export const competitionReportUrl = (id) => `/competitions/${id}/report`;

export const competitionApiUrl = (id) => `/api/v0/competitions/${id}`;

export const userRoleApiUrl = (id) => `/api/v0/user_roles/${id}`;

export const competitionEventResultsApiUrl = (id, eventId) => `/api/v0/competitions/${id}/results/${eventId}`;

export const competitionAllResultsUrl = (id, eventId) => `/competitions/${id}/results/all?event=${eventId}`;

export const omnisearchApiUrl = (query) => `/api/v0/search?q=${query}`;

export const competitionSearchApiUrl = (query) => `/api/v0/search/competitions?q=${query}`;

export const competitionEventScramblesApiUrl = (id, eventId) => `/api/v0/competitions/${id}/scrambles/${eventId}`;

export const competitionScramblesUrl = (id, eventId) => `/competitions/${id}/scrambles?event=${eventId}`;

export const adminCheckExistingResultsUrl = (competitionId) => `/competitions/${competitionId}/admin/check-existing-results`;

export const adminCheckRegionalRecordsUrl = (competitionId, eventId) => `/admin/check_regional_records?competition_id=${competitionId}&event_id=${eventId}`

export const competitionEventsDataUrl = (id) => `/admin/events_data/${id}`;

export const adminCADUrl = `/admin/compute_auxiliary_data`;

export const adminFixResultsUrl = (personId, competition_id = undefined, event_id = undefined, round_type_id = undefined) => {
  const searchParams = new URLSearchParams(`person_id=${personId}`);

  if (competition_id) {
    searchParams.append(`competition_id`, competition_id);

    if (event_id) {
      searchParams.append(`event_id`, event_id);

      if (round_type_id) {
        searchParams.append(`round_type_id`, round_type_id);
      }
    }
  }

  return `/admin/fix_results?${searchParams.toString()}`
};

export const paymentFinishUrl = (competitionId) => `https://staging.worldcubeassociation.org/competitions/${competitionId}/payment-completion`

export const availableRefundsUrl = (competitionId, userId) => `/payment/refunds?attendee_id=${competitionId}-${userId}`

export const refundPaymentUrl = (competitionId, paymentIntegration, paymentId) => `/competitions/${competitionId}/refund/${paymentIntegration}/${paymentId}`

export const paymentDenominationUrl = (amount, currencyIsoCode) => `/registration/payment-denomination?amount=${amount}&currency_iso=${currencyIsoCode}`

export const adminGenerateIds = `/persons/new_id`;

export const personApiUrl = (wcaId) => `/api/v0/persons/${wcaId}`;

export const userApiUrl = (id) => `/api/v0/users/${id}`;

export const personSearchApiUrl = (query) => `/api/v0/search/persons?q=${query}`;

export const userSearchApiUrl = (query) => `/api/v0/search/users?q=${query}`;

export const geocodingApiUrl = `/api/v0/geocoding/search`;

export const competitionAnnouncementDataUrl = (competitionId) => `/competitions/${competitionId}/announcement_data`
export const competitionUserPreferencesUrl = (competitionId) => `/competitions/${competitionId}/user_preferences`
export const competitionConfirmationDataUrl = (competitionId) => `/competitions/${competitionId}/confirmation_data`

export const announceCompetitionUrl = (competitionId) => `/competitions/${competitionId}/announce`
export const cancelCompetitionUrl = (competitionId, undo = false) => `/competitions/${competitionId}/cancel?undo=${undo}`
export const closeRegistrationWhenFullUrl = (competitionId) => `/competitions/${competitionId}/close_full_registration`

export const updateUserNotificationsUrl = (competitionId) => `/competitions/${competitionId}/user_preference/notifications`

export const confirmCompetitionUrl = (competitionId) => `/competitions/${competitionId}/confirm`

export const competitionNearbyJsonUrl = `/competitions/edit/nearby-competitions-json`;

export const seriesEligibleCompetitionsJsonUrl = `/competitions/edit/series-eligible-competitions-json`;

export const registrationCollisionsJsonUrl = `/competitions/edit/registration-collisions-json`;

export const calculateDuesUrl = `/competitions/edit/calculate_dues`;

export const adminPostingCompetitionsUrl = `/admin/posting-index.json`;

export const adminStartPostingUrl = `/admin/start-posting`;

export const adminImportResultsUrl = (competitionId) => `/competitions/${competitionId}/admin/import-results`;

export const adminCheckUploadedResults = (competitionId) => `/competitions/${competitionId}/admin/upload-results`;

export const fetchUserGroupsUrl = (groupType) => {
  const searchParams = new URLSearchParams();
  searchParams.append('groupType', groupType);
  return `/api/v0/user_groups?${searchParams.toString()}`;
};

export const addUserGroupsUrl = `/api/v0/user_groups`;

export const userGroupsUpdateUrl = (userGroupId) => `/api/v0/user_groups/${userGroupId}`;

export const countryBandsUrl = `/wfc/country-bands`;
export const subordinateDelegateClaimsUrl = `/panel/pending-claims`;
export const subordinateUpcomingCompetitionsUrl = `/competitions/for_senior`;
export const wfcCompetitionsExportUrl = `/wfc/competitions_export`;
export const generateDbTokenUrl = `/panel/generate_db_token`

export const wfcXeroUsersUrl = `/api/v0/wfc/xero_users`;

export const wfcDuesRedirectsUrl = `/api/v0/wfc/dues_redirects`;

export const pendingClaimsUrl = (userId) => `/panel/pending-claims/${userId}`;

export const competitionsUrl = ({display, years, delegate}) => `/competitions?${jsonToQueryString({ display, years, delegate })}`;

export const competitionsForSeniorUrl = (userId) => `/competitions/for_senior/${userId}`;

export const adminCheckRecordsUrl = `/admin/check_regional_records`;

export const userAvatarDataUrl = (userId) => `/users/${userId}/avatar`;

// Max number of objects fetched per query
export const WCA_API_PAGINATION = 25;

export const delegatesPageUrl = `/delegates`;

export const allDelegatePageUrl = `/delegates#all`;

export const teamsCommitteesCouncilsPageUrl = (teamFriendlyId) => `/teams-committees-councils#${teamFriendlyId}`;

export const officersAndBoardPageUrl = `/officers-and-board`;

export const translatorsPageUrl = `/translators`;

export const editUserAvatarUrl = (userId) => `/users/${userId}/edit?section=avatar`;
export const adminAvatarsUrl = `/admin/avatars`;

export const contactUrl = `/contact`

export const contactEditProfileActionUrl = `/contact/edit_profile`

export const contactRecipientUrl = (contactRecipient) => `/contact?contactRecipient=${contactRecipient}`

export const contactCompetitionUrl = (competitionId, message) => `/contact?competitionId=${competitionId}&contactRecipient=competition&message=${message}`

export const apiV0Urls = {
  users: {
    me: {
      userDetails: `/api/v0/users/me`,
      permissions: `/api/v0/users/me/permissions`,
      token: `/api/v0/users/me/token`,
    },
    show: `/api/v0/users`
  },
  persons: {
    show: (wcaId) => `/api/v0/persons/${wcaId}`,
  },
  wrt: {
    edit: (wcaId) => `/api/v0/wrt/persons/${wcaId}`,
    destroy: (wcaId) => `/api/v0/wrt/persons/${wcaId}`,
    resetClaimCount: (wcaId) => `/api/v0/wrt/persons/${wcaId}/reset_claim_count`,
  },
  userRoles: {
    list: ({isActive, isGroupHidden, userId, groupId, status, groupType, isLead} = {}, sort, perPage = 100) => `/api/v0/user_roles?${jsonToQueryString({ sort, isActive, isGroupHidden, userId, groupId, status, groupType, isLead, per_page: perPage })}`,
    create: () => `/api/v0/user_roles`,
    update: (roleId) => `/api/v0/user_roles/${roleId}`,
    delete: (roleId) => `/api/v0/user_roles/${roleId}`,
    search: (query, groupType) => `/api/v0/user_roles/search?${jsonToQueryString({ query, groupType })}`,
  },
  userGroups: {
    list: (groupType, sort, {isActive, isHidden, parentGroupId, isRootGroup} = {}) => `/api/v0/user_groups?${jsonToQueryString({ groupType, sort, isActive, isHidden, parentGroupId, isRootGroup })}`,
  },
  competitions: {
    list: `/api/v0/competitions`,
    listIndex: `/api/v0/competition_index`,
    info: (compId) => `/api/v0/competitions/${compId}`,
    registrationData: `/api/v0/registration-data`
  },
  delegates: {
    searchIndex: `/api/v0/delegates/search-index`,
  },
}

export const panelUrls = {
  board: {
    regionsManager: `/panel/board#regions-manager`,
  },
  seniorDelegate: {
    regions: `/panel/senior_delegate#regions`,
  },
  wst: {
    translators: `/panel/wst#translators`,
  },
  leader: {
    groupsManager: `/panel/leader#groups-manager`,
  },
}

export const actionUrls = {
  tickets: {
    show: (ticketId) => `/tickets/${ticketId}.json`,
    updateStatus: (ticketId) => `/tickets/${ticketId}/update_status`,
  },
}

export const userPreferencesRoute = `/profile/edit?section=preferences`;

export const pollingRoute = (userId, competitionId) => `https://t7ni46t9jd.execute-api.us-west-2.amazonaws.com/v1/staging?competition_id=${competitionId}&user_id=${userId}`;

export const wcaRegistrationUrl = `https://staging.registration.worldcubeassociation.org`;

export const getPsychSheetForEventUrl = (competitionId, eventId, sortBy) => `/api/v0/competitions/${competitionId}/psych-sheet/${eventId}?sort_by=${sortBy}`

export const editRegistrationUrl = (userId, competitionId) => `/competitions/registrations_v2/${competitionId}/${userId}/edit`

export const eligibleVotersUrl = `/admin/all-voters`;

export const leaderSeniorVotersUrl = `/admin/leader-senior-voters`;

export const confirmedRegistrationsUrl = (competitionId) => `/api/v1/registrations/${competitionId}`;

export const allRegistrationsUrl = (competitionId) => `/api/v1/registrations/${competitionId}/admin`;

export const singleRegistrationUrl = (competitionId, userId) => `/api/v1/registrations/register?competition_id=${competitionId}&user_id=${userId}`;

export const submitRegistrationUrl = `/api/v1/registrations/register`;

export const updateRegistrationUrl = `/api/v1/registrations/register`;

export const bulkUpdateRegistrationUrl = `/api/v1/registrations/bulk_update`;

export const paymentTicketUrl = (competitionId, donationIso) => `/api/v1/registrations/${competitionId}/payment?donation_iso=${donationIso}`;
